import { setDoc, doc, getDoc } from 'firebase/firestore'
import db from '../firebase.js'
import React, { useState } from 'react'

function DisplayCode(props) {

  const [stage, setStage] = useState(false)
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const code = props.value[0]
  const key = props.value[1]
  const showCode = props.value[2]
  const text = [...code]

  const handleStage = async () => {
    if (name) {
      try {
        const docRef = doc(db, "checkIns", key)
        const docSnap = await getDoc(docRef)
        const data = docSnap.data()
  
        data.badge.name = name
  
        const payload = {
          ...data
        }
  
        if (docSnap.exists()) {
          setStage(true)
        }
  
        await setDoc(docRef, payload)
  
      } catch (error) {
        console.log(error)
      }
    } else {
      setError('Enter your name')
    }
  }


  const handleName = ({ target: { value } }) => {
    setName(value) 
  }

  let display

  if (!stage && showCode === true) {
    display = (
      <>

        <div className='container'>
        <img src={require('../assets/background-abc-a.png')} className='bg-top' alt='bg-top'/>
          <img src={require('../assets/background-abc-b.png')} className='bg-bot-sec' alt='bg-top'/>
          <div className='inner-content'>
            <div className='logo-code'>
              <img src={require('../assets/Logo.png')} className='logo' alt='Google Logo' />
            </div>
      
            <div className='box-code'>
              <input className='box-input' value={name} onChange={handleName} />
              <div className='sub-text'> What would you like to be called? </div>
      
              <div className='error'> {error} </div>
      
              <div className='button-code' onClick={handleStage}>
                <div className='button-74'>
                  Get Code
                </div>
              </div>
            </div>
    
            <div className='google-pic'>
              <img src={require('../assets/background-code.png')} className='background' alt='Google Background' />
              <img src={require('../assets/background-aa.png')} className='bg-code' alt='sec-bg'/>
            </div>
          </div>
        </div>

        

      </>
    )
  } else {
    display = (
      <>
        <div className='container'>
          <img src={require('../assets/background-abc-a.png')} className='bg-top' alt='bg-top'/>
          <img src={require('../assets/background-abc-b.png')} className='bg-bot-sec' alt='bg-top'/>
            <div className='inner-content'>
              <div className='logo-code'>
                <img src={require('../assets/Logo.png')} className='logo' alt='Google Logo' />
              </div>
        
              <div className='box-code'>
                <div className='text-code'> 
                  {text}
                </div>
                <div className='sub-text'> Your code is </div>
              </div>
      
              <div className='google-pic'>
                <img src={require('../assets/background-code.png')} className='background' alt='Google Background' />
                <img src={require('../assets/background-aa.png')} className='bg-code' alt='sec-bg'/>
              </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      { display }
    </>
  )
}

export default DisplayCode