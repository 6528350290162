import React, { useState, useEffect } from 'react'
import './style.scss'


const CountdownTimer = ({ onChangePage, otherProp }) => {


  const targetTimestamp = otherProp
  const targetDate = new Date(targetTimestamp * 1000).getTime()

  const calculateTimeRemaining = () => {
    const now = new Date().getTime()
    const timeRemaining = targetDate - now

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

    return { days, hours, minutes, seconds }
  }

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining())

  useEffect(() => {
    const timer = setInterval(() => {
      if (targetTimestamp) {
        setTimeRemaining(calculateTimeRemaining())
      }
    }, 1000)

    if (timeRemaining.days <= 0 && timeRemaining.hours <= 0 && timeRemaining.minutes <= 0 && timeRemaining.seconds <= 0) {
      onChangePage()
    }

    return () => {
      clearInterval(timer)
    }
  })

  

  return (
    <>
      <div className='container'>
        <img src={require('../assets/background-abc-a.png')} className='bg-top' alt='bg-top'/>
        <img src={require('../assets/background-abc-b.png')} className='bg-bot' alt='bg-top'/>
        <div className='inner-content'>
          <div className='google-logo'>
            <img src={require('../assets/Logo.png')} className='logo' alt='Google Logo' />
          </div>
          <div className='box'>
            <div className='text'>
              { timeRemaining.days }:{ timeRemaining.hours }:{ timeRemaining.minutes }
            </div>
            <div className='sub-text'> Days to go! </div>
          </div>
          <div className='google-pic'>
            <img src={require('../assets/background-code.png')} className='background' alt='Google Background' />
            <img src={require('../assets/background-aa.png')} className='sec-bg' alt='sec-bg'/>
          </div>
        </div>
        
      </div>
    </>

  );
}

export default CountdownTimer;
