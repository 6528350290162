
import './App.css';
import CheckInPage from './components/CheckInPage';

function App() {
  return (
    <div className='check-in App'>
      < CheckInPage />
    </div>
  );
}

export default App;
