import React from 'react'
import { useEffect, useState } from 'react'

function ThrowPage() {

  const [display, setDisplay] = useState(true);
  

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplay(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  let show 

  if(display === true) {
    show = (
      <>
        <div className='loading'>
          <span className='loader'></span>
        </div>
      </>
    )
  } else {
    show = (
      <>
        <div className='oops'>
          <div className='t-1'>O</div>
          <div className='t-2'>O</div>
          <div className='t-3'>P</div>
          <div className='t-4'>S</div>
          <div className='t-5'>!</div>
        </div>
        <div className='text'> 404 THE PAGE CAN'T BE FOUND</div>
      </>
    )
  }

  return (
      <div className='error-page'>
        { show }
      </div>
  )
}

export default ThrowPage