import { useEffect, useState } from 'react'
import { onSnapshot, setDoc, doc, getDoc } from 'firebase/firestore'
import db from '../firebase.js'
import './style.scss'
import DisplayCode from './DisplayCode.js'
import CountdownTimer from './CountdownTimer.js'
import ThrowPage from './ThrowPage.js'


function CheckInPage() {

  const [key, setKey] = useState('')
  const [code, setCode] = useState({ code: "loading..." })
  const [page, setPage] = useState('1')
  const [error, setError] = useState('')
  const [time, setTime] = useState(null)
  const [showCode, setShowCode] = useState(false)

  const baseUrl = window.location.href
  const urlObject = new URL(baseUrl)
  const userId = urlObject.searchParams.get("code")

  useEffect(() => {
    if (userId) {
      setKey(userId)
    }
  }
    , [userId]
  )

  useEffect(() => {
    if (key) {
      const unsubscribe = onSnapshot(doc(db, "checkIns", key), (doc) => {
        setTime(doc.data().event.startAt.seconds)
      });

      return () => unsubscribe();
    }
  }, [key])

  const handleUserKey = async () => {
    try {
      const docRef = doc(db, "checkIns", key)
      const docSnap = await getDoc(docRef)
      let payload = { ...docSnap.data()}

      if (docSnap.data().status !== 'PRINTED') {
        payload = {
          ...docSnap.data(),
          status: 'CHECKED_IN',
          updatedAt: dateUpdate
        }

        setShowCode(true)
        console.log('this status is not check-in')
      }

      if (docSnap.exists()) {
        const dataUser = docSnap.data()
        setCode(dataUser.attendee.code)
        setPage('3')

      }

      await setDoc(docRef, payload)
    } catch {
      setError('Not Found!')
    }
  }

  function formatDate(date) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    };

    const utcPlus7Date = new Date(date.getTime());

    const formatter = new Intl.DateTimeFormat('en-US', options)
    const formattedDate = formatter.format(utcPlus7Date)
    return formattedDate + ' UTC+7'
  }

  const dateNow = new Date()
  const dateUpdate = formatDate(dateNow)

  const handleStartCheckIn = () => {
    setPage('2')
  }

  let display

  if (page === '2') {
    display = (
      <>
        <div className='container'>
          <img src={require('../assets/background-abc-a.png')} className='bg-top' alt='bg-top'/>
          <img src={require('../assets/background-abc-b.png')} className='bg-bot' alt='bg-top'/>
          <div className='inner-content'>
            <div className='google-logo'>
              <img src={require('../assets/Logo.png')} className='logo' alt='Google Logo' />
            </div>
            <div className='error'> {error} </div>
            <div className='button' onClick={handleUserKey} >
              <div className='button-74'>
                Check In
              </div>
            </div>
    
            <div className='google-pic'>
              <img src={require('../assets/background-code.png')} className='background' alt='Google Background' />
              <img src={require('../assets/background-aa.png')} className='sec-bg' alt='sec-bg'/>
            </div>
          </div>
        </div>
      </>
    )
  } else if (page === '3') {
    display = < DisplayCode value={[code, key, showCode]} />
  } else if (time && page === '1') {
    display = time !== null && < CountdownTimer onChangePage={handleStartCheckIn} otherProp={time} />
  } else {
    display = < ThrowPage />
  }

  return (
    <>
      { display }
    </>
  )
}

export default CheckInPage